<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
      <c-table
        ref="grid"
        :title="title"
        tableId="grid"
        :columnSetting="false"
        :isFullScreen="false"
        :columns="grid.columns"
        :filtering="false"
        :data="grid.data"
        selection="multiple"
        rowKey="envAirMstOutletPartId"
        :isExcelDown="false"
        :editable="editable"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addrow1" />
            <c-btn label="삭제" v-if="editable" icon="remove" @btnClicked="removeRow1" />
            <c-btn label="저장" v-if="editable" icon="save" @btnClicked="saveRow1" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props }">
          <template>
            <q-chip
              v-if="props.row.editFlag !== 'C'"
              outline square
              color="blue"
              :clickable="true"
              text-color="white"
              style="margin-top:3px !important;"
              @click.stop="() => { rowClick(props.row) }">
              ▶
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <c-table
        ref="grid2"
        :title="title2"
        tableId="grid2"
        :columnSetting="false"
        :isFullScreen="false"
        :columns="grid2.columns"
        :filtering="false"
        :data="grid2.data"
        selection="multiple"
        rowKey="envAirMstOutletPartHistoryId"
        :isExcelDown="false"
        :editable="editable"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" v-if="editable && selectedenvAirMstOutletPartId" icon="add" @btnClicked="addrow2" />
            <c-btn label="삭제" v-if="editable && selectedenvAirMstOutletPartId" icon="remove" @btnClicked="removeRow2" />
            <c-btn label="저장" v-if="editable && selectedenvAirMstOutletPartId" icon="save" @btnClicked="saveRow2" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'gov-schedule-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        govScheduleMstId: '',
      }),
    },
  },
  data() {
    return {
      title: '',
      title2: '부품별 교체/청소 이력',
      grid: {
        columns: [],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'outletPartExecType',
            field: 'outletPartExecType',
            label: '구분',
            align: 'center',
            type: 'select',
            style: 'width:70px',
            comboItems: [
              { code: 'OPET000001', codeName: '청소' },
              { code: 'OPET000002', codeName: '교체' },
            ],
            sortable: true,
            required: true,
          },
          {
            name: 'execDt',
            field: 'execDt',
            label: '교체/청소일',
            align: 'center',
            type: 'date',
            style: 'width:120px',
            sortable: true,
            required: true,
          },
          {
            name: 'execUserName',
            field: 'execUserName',
            label: '교체/청소자',
            align: 'center',
            type: 'user',
            userId: 'execUserId',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '내용',
            type: 'text',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      nowplantCd: '',
      detailUrl: '',
      listUrl: '',
      insertUrl: '',
      deleteUrl: '',
      hislistUrl: '',
      hisinsertUrl: '',
      hisdeleteUrl: '',
      editable: true,
      selectedenvAirMstOutletPartId: '',
      selectedenvAirMstOutletPartName: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.detailUrl = selectConfig.env.air.mst.outlet.get.url;
      this.listUrl = selectConfig.env.air.mst.outlet.part.url;
      this.insertUrl = transactionConfig.env.air.mst.outlet.part.save.url;
      this.deleteUrl = transactionConfig.env.air.mst.outlet.part.delete.url;
      this.hislistUrl = selectConfig.env.air.mst.history.list.url;
      this.hisinsertUrl = transactionConfig.env.air.mst.history.save.url;
      this.hisdeleteUrl = transactionConfig.env.air.mst.history.delete.url;

      this.getDetail();
      this.$comm.getComboItems('ENV_AIR_MST_OUTLET_PART_TYPE').then(_result => {
        this.grid.columns = [
          {
            name: 'envAirMstOutletPartType',
            field: 'envAirMstOutletPartType',
            label: '부품타입',
            align: 'center',
            type: 'select',
            required: true,
            comboItems: _result,
            style: 'width:110px',
            sortable: true,
          },
          {
            name: 'envAirMstOutletPartName',
            field: 'envAirMstOutletPartName',
            label: '부품명',
            align: 'left',
            type: 'text',
            required: true,
            sortable: true,
          },
          {
            name: 'cleanCycleTerm',
            field: 'cleanCycleTerm',
            label: '청소주기',
            align: 'center',
            type: 'number',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'cleanCycleCd',
            field: 'cleanCycleCd',
            label: '청소주기',
            align: 'center',
            type: 'select',
            style: 'width:70px',
            comboItems: [
              { code: 'Y', codeName: '년' },
              { code: 'M', codeName: '월' },
              { code: 'D', codeName: '일' },
            ],
            sortable: true,
          },
          {
            name: 'cleanNextDt',
            field: 'cleanNextDt',
            label: '다음청소예정일',
            align: 'center',
            style: 'width:110px',
            sortable: true,
          },
          {
            name: 'changeCycleTerm',
            field: 'changeCycleTerm',
            label: '교체주기',
            align: 'center',
            type: 'number',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'changeCycleCd',
            field: 'changeCycleCd',
            label: '교체주기',
            align: 'center',
            type: 'select',
            style: 'width:70px',
            comboItems: [
              { code: 'Y', codeName: '년' },
              { code: 'M', codeName: '월' },
              { code: 'D', codeName: '일' },
            ],
            sortable: true,
          },
          {
            name: 'changeNextDt',
            field: 'changeNextDt',
            label: '다음교체예정일',
            style: 'width:110px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'sizeName',
            field: 'sizeName',
            label: '사이즈',
            align: 'center',
            type: 'text',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'amount',
            field: 'amount',
            label: '수량',
            style: 'width:60px',
            align: 'center',
            type: 'number',
            sortable: true,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '이력',
            align: 'center',
            style: 'width:60px',
            type: 'custom',
            sortable: false
          },
        ]
        this.getList();
      });
    },
    getDetail() {
      if (this.param.envAirMstOutletId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envAirMstOutletId: this.param.envAirMstOutletId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.nowplantCd = _result.data.plantCd;
          this.title = '방지시설 부품목록 ('+_result.data.envAirMstOutletName+')'
        },);
      }
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
      var _table2 = document.getElementsByClassName('bg-light-blue-1')[1];
      if (_table2) {
        _table2.classList.remove('bg-light-blue-1');
      }
    },
    getEnvList() {
      this.getList();
      this.reset();
    },
    reset() {
      this.rowRemoveSelect();
      this.grid2.data = [];
      this.selectedenvAirMstOutletPartId = '';
      this.selectedenvAirMstOutletPartName = '';
      this.title2 = '부품별 교체/청소 이력';
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {envAirMstOutletId: this.param.envAirMstOutletId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      this.selectedenvAirMstOutletPartId = row.envAirMstOutletPartId;
      this.selectedenvAirMstOutletPartName = row.envAirMstOutletPartName;
      this.title2 = '부품별 교체/청소 이력 (' + this.selectedenvAirMstOutletPartName + ')';
      this.$http.url = this.hislistUrl;
      this.$http.type = 'GET';
      this.$http.param = {envAirMstOutletPartId: row.envAirMstOutletPartId}
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
    },
    addrow1() {
      this.rowRemoveSelect();
      this.grid2.data = [];
      this.selectedenvAirMstOutletPartId = '';
      this.selectedenvAirMstOutletPartName = '';
      this.title2 = '부품별 교체/청소 이력';
      this.grid.data.push({
        plantCd: this.nowplantCd,  // 사업장코드
        envAirMstOutletId: this.param.envAirMstOutletId,  // 대기배출구(방지시설) 일련번호
        envAirMstOutletPartId: uid(),  // 방지시설별 부품 일련번호
        envAirMstOutletPartName: '',  // 방지시설별 부품명
        envAirMstOutletPartType: null,  // 방지시설별 부품타입
        cleanCycleCd: null,  // 청소주기구분(일,월,년)
        cleanCycleTerm: null,  // 청소주기기간
        cleanNextDt: '',  // 다음청소예정일자
        changeCycleCd: null,  // 교체주기구분(일,월,년)
        changeCycleTerm: null,  // 교체주기기간
        changeNextDt: '',  // 다음교체예정일자
        sizeName: '',  // 사이즈
        amount: null,  // 수량
        editFlag: 'C',
      });
    },
    removeRow1() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRow1() {
      let checkItem = ['envAirMstOutletPartType','envAirMstOutletPartName']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '필수 입력값을 입력해 주세요. [부품타입, 부품명]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.insertUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getEnvList();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addrow2() {
      this.grid2.data.push({
        envAirMstOutletPartHistoryId: uid(),  // 부품 청소/교체 일련번호
        plantCd: this.nowplantCd,  // 사업장코드
        envAirMstOutletId: this.param.envAirMstOutletId,  // 대기배출구(방지시설) 일련번호
        envAirMstOutletPartId: this.selectedenvAirMstOutletPartId,  // 방지시설별 부품 일련번호
        outletPartExecType: null,  // 청소/교체 구분
        execDt: this.$comm.getToday(),  // 실시일자
        execUserId: this.$store.getters.user.userId,  // 업무 실시자
        execUserName: this.$store.getters.user.userName,  // 업무 실시자
        remarks: '',  // 내용
        editFlag: 'C',
      });
    },
    removeRow2() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.hisdeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.getList();
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRow2() {
      let checkItem = ['outletPartExecType','execDt']
      let isConti = true;
      this.$_.forEach(this.grid2.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '필수 입력값을 입력해 주세요. [청소/교체 구분, 청소/교체일]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid2.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.hisinsertUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getList();
                this.rowClick({envAirMstOutletPartId: this.selectedenvAirMstOutletPartId});
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    }
  }
};
</script>